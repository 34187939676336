import React from 'react';

const Footer = () => {
  return (
    <footer className="footer">
      <nav>
        <img src='/images/Twitter-Logo-Red.png'
          className='mein-lady-twitter'
          onClick={() => window.open('https://x.com/MeinLadyNFT', '_blank')}
        ></img>
        <img src='/images/telegram-red.png'
          className='mein-lady-telegram'
          onClick={() => window.open('https://t.co/DyjqSngigD', '_blank')}
        ></img>
      </nav>
      <p style={{ fontFamily: "Deutsch"}}>&copy; MEIN LADY 2024 - DECENTRALIZED HISTORY</p>
    </footer>
  );
};

export default Footer;