import React from 'react';

const About = () => {
  return (
    <section className="about">
      {/* <h2>About WarCoin</h2> */}
      <p style={{ fontFamily: "Deutsch"}}>Our mission at Mein Lady is to defend free speech and freedom of expression through a decentralized, uncensorable platform that empowers creators, thinkers, and artists to share their voices without fear of suppression. We strive to create a sanctuary for uncensored content—ranging from novels and articles that challenge the status quo to a marketplace for NFTs that celebrate creativity in its purest form. By standing firm against censorship, we envision a world where every voice has the opportunity to be heard, and every idea can find its place. </p>
    </section>
  );
};

export default About;