import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';

const Collections = ({ collectionLibrary }) => {
  const [images, setImages] = useState([]);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const imagesPerPage = 48;

  console.log('Collections component rendered, collectionLibrary:', collectionLibrary);

  useEffect(() => {
    console.log('useEffect triggered, collectionLibrary:', collectionLibrary);
    if (collectionLibrary && Array.isArray(collectionLibrary) && collectionLibrary.length > 0) {
      console.log('Processing collectionLibrary');
      try {
        const validImages = collectionLibrary
          .filter((img, i) => i !== 0 && img && img.Key)
          .sort((a, b) => {
            const aMatch = a.Key.match(/(\d+)/);
            const bMatch = b.Key.match(/(\d+)/);
            return aMatch && bMatch ? parseInt(aMatch[0], 10) - parseInt(bMatch[0], 10) : 0;
          })
          .map(img => ({
            ...img,
            url: `https://library-contents.s3.us-east-2.amazonaws.com/${img.Key}`
          }));

        console.log('Processed images:', validImages);
        setImages(validImages);
      } catch (err) {
        console.error('Error processing images:', err);
        setError('Error processing images. Please check the console for more details.');
      }
    } else {
      console.log('collectionLibrary is empty or not an array');
      setError('No images available. Please check if the collectionLibrary prop is correctly passed.');
    }
  }, [collectionLibrary]);

  const getCurrentImages = () => {
    const indexOfLastImage = currentPage * imagesPerPage;
    const indexOfFirstImage = indexOfLastImage - imagesPerPage;
    return images.slice(indexOfFirstImage, indexOfLastImage);
  };

  const totalPages = Math.ceil(images.length / imagesPerPage);

  const handlePrevPage = () => {
    setCurrentPage(prev => Math.max(prev - 1, 1));
    window.scrollTo(0, 0);
  };

  const handleNextPage = () => {
    setCurrentPage(prev => Math.min(prev + 1, totalPages));
    window.scrollTo(0, 0);
  };

  console.log('Rendering component, images count:', images.length);

  const linkStyle = {
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
    color: 'inherit'
  };

  const textStyle = {
    fontSize: '24px',
    lineHeight: '48px'
  };

  return (
    <div className="collections" style={{ padding: '0px 20px 10px' }}>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '10px' }}>
        <NavLink to="/" style={{ ...linkStyle, marginRight: '20px' }}>
          <span style={textStyle}>Home</span>
        </NavLink>
        <h1 style={{ margin: '0 15px' }}>Mein Lady Gallery</h1>
        <NavLink to="/clubhouse" style={{ ...linkStyle, marginLeft: '20px' }}>
          <span style={textStyle}>Clubhouse</span>
        </NavLink>
      </div>
      {images.length === 0 ? (
        <p>No images available. Please check if the collectionLibrary prop is correctly passed.</p>
      ) : (
        <>
          <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'center', gap: '10px' }}>
            <button className='page-collection-button' onClick={handlePrevPage} disabled={currentPage === 1}>
              Previous
            </button>
            <span>Page {currentPage} of {totalPages}</span>
            <button className='page-collection-button' onClick={handleNextPage} disabled={currentPage === totalPages}>
              Next
            </button>
          </div>
          <div className="image-container-home" style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
            {getCurrentImages().map((image, index) => (
              <figure key={image.Key} style={{ margin: '0', padding: '5px', }}>
                <img
                  src={image.url}
                  alt={`Collection item ${index + 1}`}
                  onError={(e) => {
                    console.error(`Error loading image: ${image.url}`, e);
                    e.target.src = 'https://via.placeholder.com/100?text=Error';
                  }}
                />
              </figure>
            ))}
          </div>
          <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'center', gap: '10px' }}>
            <button className='page-collection-button' onClick={handlePrevPage} disabled={currentPage === 1}>Previous</button>
            <span>Page {currentPage} of {totalPages}</span>
            <button className='page-collection-button' onClick={handleNextPage} disabled={currentPage === totalPages}>Next</button>
          </div>
        </>
      )}
    </div>
  );
};

export default Collections;
