import React, { useState, useMemo, useCallback, useEffect, useRef } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import './ReadPdfModal.css';

// Import the worker configuration
import '../pdfjs-worker';

const ReadPdfModal = (props) => {
    const { pdfFile, closeModal } = props;
    const [numPages, setNumPages] = useState(null);
    const [pdfError, setPdfError] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [scale, setScale] = useState(1.0);
    const [showTextLayer, setShowTextLayer] = useState(false);
    const [pagesToRender, setPagesToRender] = useState(10);
    const observerRef = useRef(null);
    const pdfContainerRef = useRef(null);
    const lastPageRef = useRef(null);
    const renderedPagesRef = useRef([]);
    const pdfUrl = `https://library-contents.s3.us-east-2.amazonaws.com/${pdfFile.Key}`;

    const options = useMemo(() => ({
        cMapUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/cmaps/`,
        cMapPacked: true,
        workerSrc: '../pdfjs-worker.js',
    }), []);

    const onDocumentLoadSuccess = useCallback(({ numPages }) => {
        setNumPages(numPages);
        setPdfError(null);
        setIsLoading(false);
    }, []);

    const onDocumentLoadError = useCallback((error) => {
        console.error('Error loading PDF:', error);
        setPdfError(error);
        setIsLoading(false);
    }, []);

    const renderPage = useCallback(({ pageNumber }) => {
        if (pageNumber > pagesToRender) {
            return null;
        }
        return (
            <Page
                key={`page_${pageNumber}`}
                pageNumber={pageNumber}
                scale={scale}
                renderTextLayer={showTextLayer}
                renderAnnotationLayer={false}
                loading={<div>Loading page {pageNumber}...</div>}
                error={<div>Error loading page {pageNumber}</div>}
                inputRef={(ref) => {
                    if (ref) {
                        renderedPagesRef.current[pageNumber - 1] = ref;
                    }
                    if (pageNumber === pagesToRender) {
                        lastPageRef.current = ref;
                    }
                }}
            />
        );
    }, [scale, showTextLayer, pagesToRender]);

    const handleScroll = useCallback((e) => {
        const container = e.target;
        const scrollPosition = container.scrollTop;
        let accumulatedHeight = 0;
        let currentPageNumber = 1;

        for (let i = 0; i < renderedPagesRef.current.length; i++) {
            const page = renderedPagesRef.current[i];
            if (page) {
                const pageHeight = page.getBoundingClientRect().height;
                if (accumulatedHeight + pageHeight > scrollPosition) {
                    currentPageNumber = i + 1;
                    break;
                }
                accumulatedHeight += pageHeight;
            }
        }

        setCurrentPage(currentPageNumber);
    }, []);

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.1
        };

        const observer = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting && pagesToRender < numPages) {
                setPagesToRender(prevPages => Math.min(prevPages + 10, numPages));
            }
        }, options);

        if (lastPageRef.current) {
            observer.observe(lastPageRef.current);
        }

        observerRef.current = observer;

        return () => {
            if (observerRef.current) {
                observerRef.current.disconnect();
            }
        };
    }, [pagesToRender, numPages]);

    return (
        <div className="modal">
            <div className="modal-content">
                <div className="modal-header">
                    <span className="page-counter">Page {currentPage} of {numPages}</span>
                    <button className="close-modal" onClick={closeModal}>Return To Library</button>
                </div>
                <div className="pdf-container" onScroll={handleScroll} ref={pdfContainerRef}>
                    {isLoading && <div className="loading-indicator">Loading PDF...</div>}
                    {pdfError ? (
                        <div>
                            <p>Error loading PDF. Please try downloading it instead.</p>
                            <a href={pdfUrl} download={pdfFile} target="_blank" rel="noopener noreferrer">
                                Download PDF
                            </a>
                        </div>
                    ) : (
                        <Document
                            file={pdfUrl}
                            onLoadSuccess={onDocumentLoadSuccess}
                            onLoadError={onDocumentLoadError}
                            options={options}
                            loading={<div className="loading-indicator">Loading PDF...</div>}
                        >
                            {Array.from(new Array(numPages), (el, index) => renderPage({ pageNumber: index + 1 }))}
                        </Document>
                    )}
                </div>
                <div className="download-link">
                    <a href={pdfUrl} download={pdfFile} target="_blank" rel="noopener noreferrer">
                        Download PDF
                    </a>
                </div>
            </div>
        </div>
    );
}

export default ReadPdfModal;
