import React from 'react';
import { NavLink } from "react-router-dom";


const WarGallery = () => {
  const warImages = [
    { src: '/images/m1.jpg', alt: 'mein_lady_1', caption: 'Friendly Citizen, Germany 1932' },
    { src: '/images/m2.jpg', alt: 'mein_lady_2', caption: 'Enlightened General, Germany 1938' },
    { src: '/images/m3.jpg', alt: 'mein_lady_3', caption: 'Man on a mission, Germany 1941' },
  ];

  return (
    <React.Fragment>
      <section className="war-gallery">
        {/* <h2>Historical War Gallery</h2> */}
        {/* <h2>Welcome to WarCoin</h2> */}
        <section className="war-gallery-buttons">
          <button
            onClick={() => window.open('https://www.sniper.xyz/collection/mein_lady', '_blank')}
          >
            <p style={{ color: 'black' }}>Shop</p>
          </button>

          <NavLink
            to="/collections"
            className="collectons-link"
          >
            <button>
              <p style={{ color: 'black' }}> Full Collection</p>
            </button>
          </NavLink>
          <NavLink
            to="/clubhouse"
            className="collectons-link"
          >
            <button>
              <p style={{ color: 'black' }}>Clubhouse</p>
            </button>
          </NavLink>

        </section>
        <div className="image-container-home">
          {warImages.map((image, index) => (
            <figure key={index}>
              <img src={image.src} alt={image.alt} />
              <figcaption> <p>{image.caption}</p> </figcaption>
            </figure>
          ))}
        </div>
      </section>
    </React.Fragment>
  );
};

export default WarGallery;