import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { WalletConnector } from './WalletConnector';


const Header = () => {
  return (
    <header className="header">
      <div className="header-container">
      <NavLink
            to="/"
            className="home-link"
          >
        <img src="/images/MyLadyLogoFullNoBG.png" alt="mein-lady-logo" className="header-image left spin2"/>
        </NavLink>
        <div className="header-content">
          <h1>Mein Lady</h1>
        </div>
        <NavLink
            to="/"
            className="home-link"
          >
        <img src="/images/MyLadyLogoFullNoBG.png" alt="mein-lady-logo" className="header-image right spin"/>
        </NavLink>
      </div>
    </header>
  );
};

export default Header;