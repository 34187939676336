import React from 'react';

const PdfRender = ({ pngFile, onClick }) => {
    return (
        <div className="pdf-thumbnail" onClick={onClick} style={{ cursor: 'pointer' }}>
            <img 
                src={`/Library-Contents-Thumbnails/${pngFile}`} 
                alt={pngFile}
                style={{ width: '150px', height: 'auto' }}
            />
            <p>{pngFile.replace('.png', '')}</p>
        </div>
    );
};

export default PdfRender;