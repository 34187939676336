import React, { useState, useEffect } from 'react';
import { useWallet } from '@solana/wallet-adapter-react';
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import 'react-tabs/style/react-tabs.css';
import './Clubhouse.css';
import axios from 'axios';
import PdfRender from '../PdfRender';
import ReadPdfModal from '../ReadPdfModal';
import { NavLink } from 'react-router-dom';
import clubhouseCategories from './clubhouse_categories.json';

const COLLECTION_ADDRESS = '33P7yQvetnmtDg2U4nLD74LniKKbDBrws1JDiK976qwn';

const Clubhouse = ({ libraryContents }) => {
    const { publicKey, connected } = useWallet();
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const [tabIndex, setTabIndex] = useState(0);
    const [ownsNFT, setOwnsNFT] = useState(false);
    const [ownerAddress, setOwnerAddress] = useState('');
    const [pngFiles, setPngFiles] = useState([]);
    const [pdfFiles, setPdfFiles] = useState([]);
    const [showPdfModal, setShowPdfModal] = useState(false);
    const [currentPdf, setCurrentPdf] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('Full Library');

    useEffect(() => {
        const checkMeinLadyOwnership = async () => {
            if (connected && publicKey) {
                const config = {
                    headers: {
                        "Content-Type": "application/json",
                    },
                };
                const data = {
                    jsonrpc: "2.0",
                    id: 1,
                    method: "getAssetsByOwner",
                    params: {
                        'ownerAddress': publicKey.toBase58(),
                        'limit': 100,
                        'page': 1
                    }
                };
                try {
                    const response = await axios.post(
                        "https://thrilling-blissful-road.solana-mainnet.quiknode.pro/79c7afba42ca3b88f80293d392ade11944896092/",
                        data,
                        config
                    );
                    const ownsMeinLady = response.data.result.items.some(
                        item => item.grouping.some(
                            group => group.group_key === "collection" &&
                                group.group_value === COLLECTION_ADDRESS
                        )
                    );
                    setOwnsNFT(ownsMeinLady);
                } catch (err) {
                    console.error(err);
                    setOwnsNFT(false);
                }
            } else {
                setOwnsNFT(false);
            }
        };

        checkMeinLadyOwnership();
    }, [connected, publicKey]);

    useEffect(() => {
        if (publicKey) {
            setOwnerAddress(publicKey.toBase58());
        } else {
            setOwnerAddress('');
        }
    }, [publicKey]);

    useEffect(() => {
        const fetchFiles = async () => {
            try {
                const timestamp = new Date().getTime();
                const pngResponse = await fetch(`/png-list.json?t=${timestamp}`);
                const pdfResponse = await fetch(`/pdf-list.json?t=${timestamp}`);
                if (!pngResponse.ok || !pdfResponse.ok) {
                    throw new Error(`HTTP error! status: ${pngResponse.status} ${pdfResponse.status}`);
                }
                const pngData = await pngResponse.json();
                const pdfData = await pdfResponse.json();
                setPngFiles(pngData.pngFiles);
                setPdfFiles(pdfData.pdfFiles);
            } catch (error) {
                console.error('Error fetching files:', error);
            }
        };

        fetchFiles();
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (newMessage.trim() && ownerAddress) {
            const message = {
                text: newMessage,
                user: ownerAddress,
                timestamp: new Date().toISOString()
            };
            setMessages([...messages, message]);
            setNewMessage('');
        }
    };

    const handlePdfClick = (pngFile) => {
        const pdfFileName = pngFile.replace('.png', '.pdf');
        const pdfFile = libraryContents.find(item => item.Key.endsWith(pdfFileName));
        setCurrentPdf(pdfFile);
        setShowPdfModal(true);
    };

    const linkStyle = {
        textDecoration: 'none',
        display: 'flex',
        alignItems: 'center',
        color: 'inherit'
    };

    const textStyle = {
        fontSize: '24px',
        lineHeight: '48px'
    };

    const handleCategoryClick = (category) => {
        setSelectedCategory(category);
    };

    const getFilteredPdfs = () => {
        if (selectedCategory === 'Full Library') {
            return pngFiles;
        }
        const category = clubhouseCategories.find(cat => cat.category === selectedCategory);
        return category ? pngFiles.filter(pngFile => category.pdfs.includes(pngFile.replace('.png', '.pdf'))) : [];
    };

    return (
        <div className="clubhouse">
            <div className='clubhouse-tab-wrapper'>
                <NavLink to="/collections" style={{ ...linkStyle, marginRight: '20px' }}>
                    <span style={textStyle}>Collection</span>
                </NavLink>
                <div className="clubhouse-buttons-wrapper">
                    <div className="wallet-adapter-button-wrapper">
                        <WalletMultiButton />
                    </div>
                    <button className='clubhouse-button' onClick={() => setTabIndex(0)}>Library</button>
                </div>
                <NavLink to="/" style={{ ...linkStyle, marginLeft: '20px' }}>
                    <span style={textStyle}>Home</span>
                </NavLink>
            </div>
            {!connected ? (
                <p>Please connect your Solana wallet to access the Clubhouse.</p>
            ) : !ownsNFT ? (
                <p>You need to own an NFT from the specified collection to access the Clubhouse.</p>
            ) : (
                <>
                    {tabIndex === 0 && (
                        <div className="library">
                            <div className="category-buttons">
                                <button
                                    className={`category-button ${selectedCategory === 'Full Library' ? 'active' : ''}`}
                                    onClick={() => handleCategoryClick('Full Library')}
                                >
                                    Full Library
                                </button>
                                {clubhouseCategories.map((category, index) => (
                                    <button
                                        key={index}
                                        className={`category-button ${selectedCategory === category.category ? 'active' : ''}`}
                                        onClick={() => {
                                            handleCategoryClick(category.category);
                                            console.log(category.category);
                                            console.log(selectedCategory);
                                        }}
                                    >
                                        <p style={{color: 'black'}}>{category.category}</p>
                                    </button>
                                ))}
                            </div>
                            <div className="pdf-grid">
                                {getFilteredPdfs().map((pngFile, index) => (
                                    <PdfRender
                                        key={index}
                                        pngFile={pngFile}
                                        onClick={() => handlePdfClick(pngFile)}
                                    />
                                ))}
                            </div>
                        </div>
                    )}
                    {tabIndex === 1 && (
                        <div className="chat-messages">
                            <h2>Clubhouse Chat Forum</h2>
                            <div className="chat-messages">
                                {messages.map((msg, index) => (
                                    <div key={index} className="message">
                                        <span className="user">{msg.user.slice(0, 4)}...{msg.user.slice(-4)}: </span>
                                        <span className="text">{msg.text}</span>
                                    </div>
                                ))}
                            </div>
                            <form onSubmit={handleSubmit}>
                                <input
                                    type="text"
                                    value={newMessage}
                                    onChange={(e) => setNewMessage(e.target.value)}
                                    placeholder="Type your message..."
                                />
                                <button type="submit">Send</button>
                            </form>
                        </div>
                    )}
                    {showPdfModal && (
                        <ReadPdfModal
                            pdfFile={currentPdf}
                            closeModal={() => setShowPdfModal(false)}
                        />
                    )}
                </>
            )}
        </div>
    );
};

export default Clubhouse;
