import React, { useMemo, useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react';
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import {
  PhantomWalletAdapter,
  SolflareWalletAdapter,
  TorusWalletAdapter,
  LedgerWalletAdapter
} from '@solana/wallet-adapter-wallets';
import { WalletModalProvider } from '@solana/wallet-adapter-react-ui';
import { clusterApiUrl } from '@solana/web3.js';
import AWS from 'aws-sdk';
import { Buffer } from 'buffer';

import Header from './components/Header';
import About from './components/About';
import WarGallery from './components/WarGallery';
import Features from './components/Features';
import Footer from './components/Footer';
import Collections from './components/Collections';
import Clubhouse from './components/clubhouse/Clubhouse';
import './App.css';

//TODO
//Fix wallet bug
//Style buttons


// Default styles that can be overridden by your app
require('@solana/wallet-adapter-react-ui/styles.css');

// Make Buffer available globally
window.Buffer = Buffer;

// Configure AWS SDK
AWS.config.update({
  region: 'us-east-2', // replace with your region
  credentials: new AWS.CognitoIdentityCredentials({
    IdentityPoolId: 'us-east-2:bfe5662d-52cc-43d0-a86d-ce3a04856292' // replace with your Identity Pool ID
  })
});

const s3 = new AWS.S3({
  signatureVersion: 'v4',
  params: { Bucket: 'library-contents' } // replace with your bucket name
});

function App() {
  // The network can be set to 'devnet', 'testnet', or 'mainnet-beta'.
  const network = WalletAdapterNetwork.Devnet;
  const [libraryContents, setLibraryContents] = useState([]);
  const [collectionLibrary, setCollectionLibrary] = useState([]);

  useEffect(() => {
    listObjects('library-contents', setLibraryContents);
    listObjects('collection-library', setCollectionLibrary);
  }, []);

  async function listObjects(prefix, setFunction) {
    const params = {
      Prefix: prefix + '/',
      MaxKeys: 1500 // You can adjust this value as needed
    };

    let allObjects = [];
    let isTruncated = true;

    while (isTruncated) {
      try {
        const data = await s3.listObjectsV2(params).promise();
        console.log('DATA');
        console.log(data);
        allObjects = [...allObjects, ...data.Contents];
        isTruncated = data.IsTruncated;

        if (isTruncated) {
          params.ContinuationToken = data.NextContinuationToken;
        }
      } catch (err) {
        console.error("Error", err);
        break;
      }
    }
    setFunction(allObjects);
  }

  // You can also provide a custom RPC endpoint.
  const endpoint = useMemo(() => clusterApiUrl(network), [network]);

  const wallets = useMemo(
    () => [
      new PhantomWalletAdapter(),
      new SolflareWalletAdapter(),
      new TorusWalletAdapter(),
      new LedgerWalletAdapter()
    ],
    []
  );

  return (
    <ConnectionProvider endpoint={endpoint}>
      <WalletProvider wallets={wallets} autoConnect>
        <WalletModalProvider>
          <Router>
            <div className="App">
              <div className="background">
                <Header />
                <Routes>
                  <Route path="/" element={
                    <main>
                      <WarGallery />
                      <About />
                    </main>
                  } />
                  <Route path="/collections" element={<Collections
                    collectionLibrary={collectionLibrary}
                  />} />
                  <Route path="/clubhouse" element={<Clubhouse
                    libraryContents={libraryContents}
                  />} />
                </Routes>
                <Footer />
              </div>
            </div>
          </Router>
        </WalletModalProvider>
      </WalletProvider>
    </ConnectionProvider>
  );
}

export default App;
